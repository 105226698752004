import {authStore} from "~/stores/auth"

export default defineNuxtRouteMiddleware(async (to, from, next) => {
    const app = useNuxtApp()
    const store = authStore(app.$pinia)

    await store.initAuth()

    if (store.user) {
        return navigateTo('/create/custom')
    }
})